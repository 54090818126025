
import { Component, mixins, Prop } from 'nuxt-property-decorator';
import { TIMERS } from '~/constants/timers';
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import DeviceMixin from '~/mixins/device.mixin';
import Product from '~/models/product';

@Component({
  name: 'dn-banners-carousel',
})
export default class BannersCarousel extends mixins(DeviceMixin) {
  private swiper: Swiper | null = null;
  @Prop({ type: Array, default: [] }) slides!: Product[];

  private get swiperName(): string {
    return 'swiper-bigproductcarousel';
  }

  mounted() {
    this.$nextTick(() => {
      if (this.slides.length > 1) {
        this.swiper = new Swiper(`#${this.swiperName}`, {
          modules: [Navigation, Autoplay, Pagination],
          slidesPerView: 1,
          spaceBetween: 32,
          speed: TIMERS.SLIDE_CHANGE,
          pagination: {
            el: `.swiper-controls.${this.swiperName}`,
            clickable: true,
          },
          loop: true,
          navigation: {
            nextEl: `.swiper-button-next.${this.swiperName}`,
            prevEl: `.swiper-button-prev.${this.swiperName}`,
          },
        });
      }
    });
  }
}
