
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import { VideoProviderService } from '~/services/video-provider.service';

@Component({
  name: 'dn-video-slide',
})
export default class VideoSlide extends Vue {
  @Prop({ type: String, default: null }) videoUrl!: string;
  @Prop({ type: String, default: null }) mobileVideoUrl!: string;
  @Prop({ type: String, default: null }) redirectUrl!: string;
  @Prop({ type: String, default: null }) mobileBackgroundImage!: string;
  @Prop({ type: String, default: null }) desktopBackgroundImage!: string;
  @Prop({ type: Boolean, default: true }) redirectOnDesktop!: boolean;
  @Prop({ type: Boolean, default: true }) redirectOnMobile!: boolean;

  private videoProviderService = VideoProviderService;
}
