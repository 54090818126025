import { render, staticRenderFns } from "./BigProductCardSlide.vue?vue&type=template&id=bcb9117a&scoped=true"
import script from "./BigProductCardSlide.vue?vue&type=script&lang=ts"
export * from "./BigProductCardSlide.vue?vue&type=script&lang=ts"
import style0 from "./BigProductCardSlide.vue?vue&type=style&index=0&id=bcb9117a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcb9117a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VideoSlide: require('/frontend/components/sliders/VideoSlide/VideoSlide.vue').default,ProductPriceView: require('/frontend/components/products/common/ProductPriceView.vue').default,ProductBadges: require('/frontend/components/products/common/ProductBadges.vue').default,ProductAlcoholMessage: require('/frontend/components/products/common/ProductAlcoholMessage.vue').default,Img: require('/frontend/components/ui/img/img.vue').default,ProductPriceDisplay: require('/frontend/components/products/common/ProductPriceDisplay.vue').default})
