import { render, staticRenderFns } from "./VideoSlide.vue?vue&type=template&id=776b714a&scoped=true"
import script from "./VideoSlide.vue?vue&type=script&lang=ts"
export * from "./VideoSlide.vue?vue&type=script&lang=ts"
import style0 from "./VideoSlide.vue?vue&type=style&index=0&id=776b714a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "776b714a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Img: require('/frontend/components/ui/img/img.vue').default})
