
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import Product from '~/models/product';
import { CategoryDataInterface } from '~/models/category-data.interface';
import { BADGES_TYPE } from '~/constants/badges';
import { BEER_SLUG } from '~/constants/category';
import ProductBanner from '~/models/product-banner';

@Component({
  name: 'dn-big-product-card-slide',
})
export default class BigProductCardSlide extends Vue {
  @Prop({ type: Object, required: true }) product!: Product;
  @Prop({ type: Boolean, default: false }) condensed!: boolean;
  @Prop({ type: Object, default: null }) category!: CategoryDataInterface;

  private BADGES_TYPE = BADGES_TYPE;
  private BEER_SLUG = BEER_SLUG;

  private get productHeroImage(): ProductBanner | undefined {
    return this.product?.photos?.find((photo) => photo?.heroBanner === true) || this.product?.photos?.[0];
  }
}
